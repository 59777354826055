.featured {
  width: 100%;
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
}

.myButton {
  box-shadow: 0px 0px 0px 0px #3dc21b;
  background-color: #44c767;
  border-radius: 7px;
  border: 1px solid #18ab29;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 16px;
  padding: 11px 31px;
  text-decoration: none;
}
.myButton:hover {
  background-color: #5cbf2a;
}
.myButton:active {
  position: relative;
  top: 1px;
}

.image {
  float: left;
  width: 50;
  padding-top: 0px;
}
.featuredItem {
  flex: 1;
  margin: 0px 20px;
  padding: 30px;
  border-radius: 5px;
  cursor: pointer;
  -webkit-box-shadow: 5px 5px 11px 5px #d1d1d1;
  box-shadow: 5px 5px 11px 5px #d1d1d1;
}
.featuredTitle {
  font-size: 20px;
}
.featuredMoneyContainer {
  margin: 10px 0px;
  display: flex;
  align-items: center;
}
.featuredMoney {
  font-size: 30px;
  font-weight: 600;
}
.featuredMoneyRate {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.featuredIcon {
  font-size: 14px;
  margin-left: 5px;
  color: green;
}

.featuredIcon.negative {
  color: red;
}

.featuredSub {
  font-size: 15px;
  color: grey;
}

.icon-style {
  color: #1baa79;
}
