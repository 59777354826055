.sidebar {
  flex: 1;
  height: calc(100vh - 50px);
  background-color: #1baa79;
  box-shadow: 40px rgb(159, 157, 157);
  position: sticky;
  top: 50px;
}
.sidebarWrapper {
  padding: 20px;
  color: rgb(255, 255, 255);
}

.sidebarMenu {
  margin-bottom: 10px;
}

.sidebarTitle {
  font-size: 13px;
  color: rgb(255, 255, 255);
}
.sidebarList {
  list-style: none;
  padding: 5px;
}
.sidebarlistItem {
  padding: 5px;
  cursor: pointer;
  align-items: center;
  border-radius: 10px;
}
.sidebarlistItem.active,
.sidebarlistItem:hover {
  background-color: #3fe1a8;
}
.sidebarIcon {
  margin-right: 5px;
  font-size: 20px !important;
}
