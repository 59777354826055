.widgetLarge {
  flex: 2;
  background-color: #1baa79;
  -webkit-box-shadow: 5px 5px 11px 5px #1baa79;
  box-shadow: 5px 5px 11px 5px #d1d1d1;
  padding: 20px;

  margin-right: 20px;
}
.image {
  float: left;
}
.text {
  color: white;
  left: 30px;
}
.myButton {
  box-shadow: inset 0px 1px 0px 0px #ffffff;
  background: linear-gradient(to bottom, #ffffff 5%, #f6f6f6 100%);
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px solid #dcdcdc;
  display: inline-block;
  cursor: pointer;
  color: #666666;
  font-family: Arial;
  font-size: 15px;
  font-weight: bold;
  padding: 8px 45px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #ffffff;
  margin-top: 30px;
  margin-right: 100px;
}
.myButton:hover {
  background: linear-gradient(to bottom, #f6f6f6 5%, #ffffff 100%);
  background-color: #f6f6f6;
}
.myButton:active {
  position: relative;
  top: 1px;
}
