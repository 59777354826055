.chart {
  margin: 20px;
  padding: 20px;
  -webkit-box-shadow: 5px 5px 11px 5px #d1d1d1;
  box-shadow: 5px 5px 11px 5px #d1d1d1;
}

.chartTitle {
  margin-bottom: 20px;
  align-text: center;
}
.container {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

  height: 50vh;
}
.containers {
  padding-left: 200px;
}

.chart-container {
  width: 770px;
  height: 80%;
}
.box {
  height: 30px;
  margin-right: 30px;
}
