.container {
  display: flex;
}

.others {
  flex: 4;
}
.homeWidgets {
  display: flex;
  margin: 20px;
}
