.sidenav {
  width: 130px;
  background: #1baa79;
  position: absolute;
  height: 100vh;
  padding: 0 20px;
  transition: all 1s;
}

ul {
  padding: 0px;
}
ul li {
  list-style-type: none;
  margin: 30px 0;
}
ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  display: block;
  padding: 14px 15px;
  border-radius: 6px;
}
.logo {
  width: 60px;
}

ul li a:hover,
ul li a:active {
  background: #3bd8a1;
  padding: "0px";
}

ul li a svg {
  margin-right: 0px;
}
